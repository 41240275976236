.crumbs1-slot {
  height: 44px;
}
.crumbs1 {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 16px;
  // background-color: #fff;
  .back {
    position: absolute;
    width: 28px;
    height: 28px;
    top: 8.5px;
    left: 9px;
  }
}