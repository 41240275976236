.login {
  position: relative;
  width: 100%;
  height: 100vh;
  // padding-top: 30.5px;
  box-sizing: border-box;
  background-color: rgb(25, 25, 25);
  .pt30 {
    padding-top: 30.5px;
  }
  .phone {
    display: flex;
    align-items: center;
    margin: 0 23px;
    border-bottom: 0.5px solid rgba(58, 58, 58, 1);
    padding: 20px 0 12px 0;
    label {
      font-weight: 500;
      font-size: 14px;
      color: #FFFFFF;
      margin-right: 20px;
    }
    input {
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      width: 250px;
      height: 28px;
      border: none;
      outline: none;
      background:transparent;
      color: #999999;
    }
  }
  .code {
    border-bottom: 0.5px solid rgba(58, 58, 58, 1);
    display: flex;
    align-items: center;
    margin: 0 23px;
    padding: 20px 0 12px 0;
    label {
      font-weight: 500;
      font-size: 14px;
      color: #FFFFFF;
      margin-right: 20px;
    }
    input {
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      width: 150px;
      height: 28px;
      margin-right: 25px;
      border: none;
      outline: none;
      background:transparent;
      color: #999999;
    }
    .get {
      width: 86px;
      height: 28px;
      background: rgba(65, 65, 65, 1);
      border-radius: 4px;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      line-height: 28px;
      color: #ffff;
      &:active {
        background: rgba(65, 65, 65, 0.8);
        color: rgba(255,255,255,0.5);
      }
    }
  }
  .submit {
    position: absolute;
    bottom: 80px;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    .submit-btn {
      width: 327px;
      height: 45px;
      border-radius: 22.5px;
      background-color: rgba(220, 45, 30, 1);
      color: #FFFFFF;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      line-height: 45px;
      &:active {
        background-color: rgba(220, 45, 30, 0.8);
      }
    }
  }
}