.panel {
  // margin-top: 4px;
  position: absolute;
  top: 170px;
  left: 0;
  .panel-content {
    position: relative;
    // left: -136px;
    width: 314px;
    height: 18px;
    font-size: 12px;
    color: #ffff;
    overflow-y: hidden;
    border-radius: 0 9px 9px 0;
    background-color: rgba(252, 20, 186, 0.45);
    .panel-scroll {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transition: all 0.3s;
      .panel-item {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        width: 100%;
        height: 18px;
        padding-right: 10px;
        padding-left: 24px;
        text-align: left;
        overflow: hidden; // 溢出隐藏
        white-space: nowrap; // 强制一行
        text-overflow: ellipsis; // 文字溢出显示省略号
      }
    }
    
  }
}
.text-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding-top: 14px!important;
  text-align: center;
  color: #FFFFFF;
  .text-1-1 {
    width: 164px;
    text-align: center;
  }
  .text-line-1 {
    width: 28px;
    height: 0;
    border-bottom: 1px solid #FFFFFF;
  }
  .text-line-2 {
    width: 28px;
    height: 0;
    border-bottom: 1px solid #FFFFFF;
  }
}