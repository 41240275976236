.home {
  height: 100%;
  overflow-y: scroll;
  position: relative;
  // background-image: url('../../../static/image\ 97.png'),linear-gradient(#1d043d,#01185e);
  background-image: url('https://file.yuanyinfu.com/front-end-lib/homeBG.png'),linear-gradient(#1d043d,#01185e);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  // background-attachment: fixed;
  .text-1 {
    text-align: center;
    padding: 16px 0 0 0;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
  }
  .mb14 {
    padding-bottom: 14px;
  }
  .mb10 {
    padding-bottom: 10px;
  }
  .footer {
    height: 76px;
  }
}

.home .empty-content {
  position: relative;
  width: 100vw;
  height: 100vh;
  .empty-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 60px;
      height: 60px;
    }
    span {
      padding-top: 22px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;

      text-align: center;

      color: #CDCDCD;
    }
  }
}