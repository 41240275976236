.detailedPicture {
  text-align: center;
  .detailedPicture-content {
    margin: auto;
    width: 355px;
    // height: 300px;
    // background-color: rgba(255,255,255,0.1);
    img {
      width: 100%;
    }
  }
}