$one:80.5px;
.LuckyRolling {
  text-align: center;
  .bg-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 332px;
    height: 332px;
    // background-image: url('../../../../../static/Rectangle\ 19.png'),url('../../../../../static/Rectangle\ 18.png');
    background-image: url('https://file.yuanyinfu.com/front-end-lib/rollingBg.png');
    // background-position: 6px 6px,0 0;
    // background-size: 332px 332px,332px 332px;
    background-size: 332px 332px;
    background-repeat: no-repeat,no-repeat;
  }
  .LuckyRolling-box {
    position: relative;
    width: 322px;
    height: 322px;
    .start-btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: $one;
      left: $one;
      // background: no-repeat url('../../../../../static/Frame\ 1000006089.png') center;
      background: no-repeat url('https://file.yuanyinfu.com/front-end-lib/startBG.png') center;
      background-size: 136px 136px;
      background-position: 12px 17px;
      width: 2*$one;
      height: 2*$one;
      text-align: center;
      .start-btn-row1 {
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        // color: #FD03B3;
        color: rgba(95, 0, 148, 1);

      }
      .start-btn-row2 {
        transform: scale(0.85);
        padding-top: 3px;
        font-size: 12px;
        text-align: center;
        color: #666666;
        span {
          color: #FD03B3;
        }
      }
    }
    .gray {
      // background: no-repeat url('../../../../../static/Frame\ 1000006089gray.png') center;
      .start-btn-row1 {
        font-weight: 600;
        font-size: 20px;
        text-align: center;
        // color: #FD03B3;
        color: #666666;

      }
      .start-btn-row2 {
        transform: scale(0.85);
        padding-top: 3px;
        font-size: 12px;
        text-align: center;
        color: #666666;
        span {
          color: #666666;
        }
      }
    }
    // 奖项
    .prize {
      position: absolute;
      width: $one;
      height: $one;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      // border: 1px solid blue;
      .bgPicture {
        position: absolute;
        left: -6px;
        top: 10px;
        width: 90.5px;
        // height: 90.5px;
        // background-image: url('../../../../assets/manghe.png');
        // background-position: -8px 0px;
        // background-size: 85.5px 85.5px;
        // background-repeat: no-repeat;
        // overflow: visible;
      }
      // .prizePicture {
      //   width: 58px;
      //   height: 58px;
      //   visibility: hidden;
      // }
      &.y2 {
        .bgPicture {
          top: 5px;
        }
      }
      &.y3 {
        .bgPicture {
          top: 0px;
        }
      }
      &.y4 {
        .bgPicture {
          top: -5px;
        }
      }
      
    }
    .active-prize {
      .bgPicture {
        width: 95.5px;
        // height: 97.5px;
        // left: -10px;
        // top: 0px;
      }
      // &.y2 {
      //   .bgPicture {
      //     top: 0px;
      //   }
      // }
      // &.y3 {
      //   .bgPicture {
      //     top: 0px;
      //   }
      // }
      // &.y4 {
      //   .bgPicture {
      //     top: 0px;
      //   }
      // }
      // .prizePicture {
      //   width: 66.2px;
      //   height: 66.2px;
      // }
    }
    // 坐标系
    .x1 {
      left: 0;
    }
    .x2 {
      left: $one;
    }
    .x3 {
      left: 2*$one;
    }
    .x4 {
      left: 3*$one;
    }
    .y1 {
      top: 0;
    }
    .y2 {
      top: $one;
    }
    .y3 {
      top: 2*$one;
    }
    .y4 {
      top: 3*$one;
    }

  }
}