.dialog {
  .mask {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.6);
    transition: all 0.5s;
    .content-box {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%,-50%);
      box-sizing: border-box;
      width: 315px;
      min-height: 200px;
      background: linear-gradient(180deg, #F2F6FA 0%, #F2F6FA 17.34%, #A8B0D7 96.66%);
      box-shadow: inset 0px 2px 2px #FFFFFF;
      border-radius: 8px;
      padding: 25px 16px 25px 16px;
      .close {
        position: absolute;
        right: 0px;
        top: -45px;
        width: 30px;
        height: 30px;
      }
      .text {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
        min-height: 125px;
        letter-spacing: 0px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;

        color: #333333;
        // overflow-y: scroll;
        p {
          margin-bottom: 8px;
        }
      }
      .btns {
        width: 100%;
        display: flex;
        justify-content: center;
        .btn1 {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 13px;
          width: 131px;
          height: 36px;
          // box-shadow: inset 0px 3px 2px rgba(255, 255, 255, 0.65), inset 0px -3px 1px #F4EFE2;
          // border-radius: 4px;
          // border: 0.5px solid;
          // border-image-source: linear-gradient(180deg, #FFFFFF 0%, #EBDDBE 100%);
          // background: linear-gradient(0deg, #FCF7EC, #FCF7EC),linear-gradient(180deg, #FFFFFF 0%, #EBDDBE 100%);
          background-image: url('../../assets/dialogbtn1.png');
          background-size: 131px 36px;
          background-repeat: no-repeat;
          color: #0C062B;
          font-weight: 500;
          font-size: 14px;
        }
        .btn2 {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 131px;
          height: 36px;
          // border: 0.5px solid #FFFFFF;
          // box-shadow: inset 0px 3px 2px rgba(255, 152, 232, 0.65), inset 0px -3px 1px rgba(255, 255, 255, 0.25);
          // border-radius: 4px;
          // background: linear-gradient(99.58deg, #FC00B1 4.48%, #F585ED 34.71%, #F585ED 55.13%, #6102FF 100%),linear-gradient(0deg, #FFFFFF, #FFFFFF);
          background-image: url('../../assets/dialogbtn2.png');
          background-size: 131px 36px;
          background-repeat: no-repeat;
          color: #0C062B;
          font-weight: 500;
          font-size: 14px;
        }
        .btn3 {
          // flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 285px;
          height: 33.5px;
          // background: linear-gradient(99.58deg, #FC00B1 4.48%, #F585ED 34.71%, #F585ED 55.13%, #6102FF 100%);
          // border: 0.5px solid #FFFFFF;
          // box-shadow: inset 0px 3px 2px rgba(255, 152, 232, 0.65), inset 0px -3px 1px rgba(255, 255, 255, 0.25);
          background-image: url('../../assets/dialogbtn.png');
          background-size: 285px 33.5px;
          background-repeat: no-repeat;
          // border-radius: 4px;
          color: #0C062B;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
}