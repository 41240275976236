.preheat {
  height: 100%;
  overflow-y: scroll;
  background-image: url('../../../static/image\ 97.png'),linear-gradient(#1d043d,#01185e);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  // background-attachment: fixed;
  .mb14 {
    margin-bottom: 14px;
  }
  .mb16 {
    margin-bottom: 16px;
  }
  .footer {
    height: 76px;
  }
}