* {
  padding: 0;
  margin: 0;
}
body {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #555555;
  -webkit-font-smoothing: antialiased;/*抗锯齿*/
  -moz-osx-font-smoothing: grayscale; /*抗锯齿兼容火狐*/
}
#root {
  text-align: center;
}
::-webkit-scrollbar {
  display: none;
}