.TaskList {
  text-align: center;
  // background: #ffff;
  background: url('https://file.yuanyinfu.com/front-end-lib/taskListBG.png') no-repeat;
  background-size: 355px;
  background-position: 10px 0;
  .TaskList-content {
    box-sizing: border-box;
    margin: auto;
    width: 355px;
    height: 216px;
    padding-top: 62px;
    text-align: center;
    // background: url('../../../../../static/Frame 1000006058.png') no-repeat;
    // background-size: 363px 174px;
    // background-position: -2px -10px;
    position: relative;
    .taskList-title {
      position: absolute;
      top: 15px;
      left: 10px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 700!important;
      font-size: 18px;
      color: #000000;

    } 
    // .line {
    //   position: absolute;
    //   left: 8.5px;
    //   top: 100px;
    //   width: 336px;
    //   height: 0;
    //   border-bottom: 0.5px solid #F6D3F9;
    // }
    .row {
      width: 336px;
      // height: 50px;
      padding-top: 14px;
      padding-bottom: 18px;
      margin: auto;
      margin-bottom: 8px;
      display: flex;
      // align-items: center;
      font-size: 12px;
      background: #E9F0FB;
      border: 0.3px solid rgba(28, 2, 50, 0.3);
      border-radius: 8px;
      .col1 {
        padding-left: 8px;
        margin-right: 7px;
        img {
          width: 32px;
          height: 32px;
        }
      }
      .col2 {
        flex: 1;
        .col2-1 {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #333333;
          text-align: left;
        }
        .col2-2 {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          color: #666666;
          text-align: left;
          .col2-2-n {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #FC17BC;
            margin-left: 5px;
          }
        }
      }
      .col3 {
        position: relative;
        text-align: right;
        width: 110px;
        margin-right: 9px;
        .col3-1 {
          position: absolute;
          top: 0;
          right: 0;
          width: 88px;
          height: 36px;
          img {
            width: 100%;
            height: 100%;
          }
          div {
            position: absolute;
            top: 0;
            left: 0;
            width: 88px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            color: #0C062B;
            font-weight: 600;
            font-size: 14px;
          }
        }
        .col3-2 {
          position: absolute;
          top: 36px;
          right: -5px;
          transform: scale(0.8);
        }
      }
    }
    .row:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
  .viewRule {
    box-sizing: border-box;
    height: 48px;
    padding-top: 10px;
    font-size: 13px;
    color: #0C062B;
    margin-left: 20px;
    img {
      margin-left: 4px;
      width: 10px;
      height: 10px;
      vertical-align: middle;
    }
  }
}